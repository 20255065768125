import _objectSpread from "E:/AirVenture/frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ActivityLog from "@/view/pages/partials/Activity-Log";
export default {
  name: "Activity",
  data: function data() {
    return {
      // activityTab: null,
      tabs: [{
        title: "User",
        key: "user",
        template: ActivityLog
      }, {
        title: "Customer",
        key: "customer",
        template: ActivityLog
      }, {
        title: "Property",
        key: "property",
        template: ActivityLog
      },
      /*{
        title: "Quotation",
        key: "quotation",
        template: ActivityLog,
      },*/
      {
        title: "Invoice",
        key: "invoice",
        template: ActivityLog
      }, {
        title: "Payment",
        key: "payment",
        template: ActivityLog
      },
      /*{
        title: "Job",
        key: "job",
        template: ActivityLog,
      },*/
      {
        title: "Visit",
        key: "visit",
        template: ActivityLog
      }, {
        title: "Staff",
        key: "engineer",
        template: ActivityLog
      }, {
        title: "Product & Service",
        key: "product",
        template: ActivityLog
      }]
    };
  },
  mounted: function mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Activity Log"
    }]);
  },
  computed: {
    activityTab: {
      set: function set(val) {
        var query = _objectSpread({}, this.$route.query);

        query.tab = val;

        if (val != this.activityTab) {
          this.$router.push({
            query: query
          });
        }
      },
      get: function get() {
        return this.$route.query.tab || "user";
      }
    }
  }
};